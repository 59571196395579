$(document).ready(function() {
	var toggleAffix = function(affixElement, scrollElement, wrapper) {
		var height = affixElement.outerHeight(),
			top = wrapper.offset().top;
		if (scrollElement.scrollTop() >= top) {
			wrapper.height(height);
			affixElement.addClass("affix");
		} else {
			affixElement.removeClass("affix");
			wrapper.height('auto');
		}
	};
	$('[data-toggle="affix"]').each(function() {
		var ele = $(this),
			wrapper = $('<div></div>');
		ele.before(wrapper);
		$(window).on('scroll resize', function() {
			toggleAffix(ele, $(this), wrapper);
		});
		// init
		toggleAffix(ele, $(window), wrapper);
	});
	$(".trim-field").blur(function() {
	 var str = $(this).val();
	 		str = str.trim();
	    $(this).val(str);
	});
});
jQuery(document).ready(function($) {
	$('.counter').counterUp({
		delay: 10,
		time: 1000
	});
});
jQuery(document).ready(function($) {
	$('#testimonials').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		autoplay: true,
		autoplaySpeed: 3000,
	});
});
$('[data-fancybox="gallery"]').fancybox({});
function toJson(csvData) {
	//Split all the text into seperate lines on new lines and carriage return feeds
	var allTextLines = csvData.split(/\r\n|\n/);
	//Split per line on tabs and commas
	var headers = allTextLines[0].split(/\t|,/);
	var lines = [];
	var items = [];
	for (var i = 1; i < allTextLines.length; i++) {
		var data = allTextLines[i].split(/\t|,/);
		if (data.length == headers.length) {
			var item = {
				"abbreviation": data[0],
				"state": data[1],
				"schools": data[2],
				"students": data[3]
			};
			items.push(item);
		}
	}
	return items;
}
var data;
function numberWithCommas(number) {
	var parts = number.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}
function getData(state) {
	var object;
	$.each(data, function(i, obj) {
		if (obj.abbreviation === state) object = obj;
	});
	if (object) {
		return object
	}
}
jQuery(document).ready(function($) {
	$("#map").mousemove(function(event) {
		var relX = event.pageX - $(this).offset().left;
		var relY = event.pageY - $(this).offset().top;
		$('#info-box').css('top', relY - $('#info-box').height() - 70);
		$('#info-box').css('left', relX - ($('#info-box').width()) / 2);
	});
	var fill = $('#USMap').data('fill');
	var strock = $('#USMap').data('strock');
	var hover = $('#USMap').data('hover');
	var rawFile = new XMLHttpRequest();
	rawFile.open("GET", "assets/uploads/us-states-schools-students.csv", true);
	rawFile.onreadystatechange = function() {
		if (rawFile.readyState === 4) {
			if (rawFile.status === 200 || rawFile.status == 0) {
				var allText = rawFile.responseText;
				data = toJson(allText);
			//	console.log(JSON.stringify(data));
			}
		}
	}
	rawFile.send(null);
	$('#map').usmap({
		'stateStyles': {
			fill: fill,
			"stroke-width": 2,
			'stroke': strock
		},
		'stateHoverStyles': {
			fill: hover
		},
		stateHoverAnimation: 500,
		showLabels: true,
		'mouseover': function(event, data) {
			var obj = getData(data.name);
			var text = "<div class='st'> <span> " + obj.state + " </span></div>";
			text += "<div> <span class='school' > " + numberWithCommas(obj.schools) + " </span> " + " <span class='caption' > Schools & <br>Districts</span>  </div>  ";
			text += "<div> <span class='students' > " + numberWithCommas(obj.students) + " </span> " + "<span class='caption' >  Total Students </span> </div>";
			$('#info-box ')
				.html(text)
				.show();
		},
		'mouseout': function(event, data) {
			$('#info-box ').hide();
		}
	});
});
jQuery(document).ready(function($) {
$("#rfq").show();
var state_dropdown =  $("#states_dropdown").html();
		$('#country').on('change',function(){
		    if( $(this).val()==="USA"){
					$("#state-province").replaceWith( "<div id='state-province' >" +  state_dropdown + "</div>" );
		    }
		    else{
					$("#state-province").replaceWith( "<div id='state-province' >" +  '<input type="text" name="state" class="form-control">' + "</div>" );
		    }
		});
});
